body.modal-open {
  overflow: hidden !important;
}

.highcharts-title {
  width: 95% !important;
  z-index: -1 !important;
  pointer-events: none !important;
}

.highcharts-exporting-group {
  z-index: 7 !important;
}

.highcharts-tooltip .mui-icon {
  width: 16px;
  height: 16px;
}

.highcharts-tooltip .mui-icon path {
  transform: scale(0.75);
}

.highcharts-tooltip > span {
  width: fit-content;
  /* min-width: fit-content; */
  /* max-width: 600px; */
}

a {
  text-decoration: none;
}

.leaflet-top, .leaflet-bottom {
  pointer-events: all !important;
}

@media (max-width: 899px) {
  .leaflet-right.leaflet-top {
    bottom: 0;
    top: auto;
  }
}
